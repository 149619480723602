.header-section {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 20px;
}

.header-section a {
    display: inline-block;
    padding: 15px 0 15px 0;
}

.header-section svg {
    height: auto;
    width: 100%
}

.header-section .vp-image {
    display: inline-block;
    width: 40%;
    height: auto;
    padding: 15px 0 15px 0;
}

/* Mobile devices - @media Portrait */
@media screen and (max-width: 767px) {
    .header-section .vp-image {
        width: 70%;
    }
}